<template>
  <div class="card">
    <div class="title-box">
      <div class="titel-icon">
        <img src="~@/assets/order/scan-code.png" class="icon-img" />
      </div>
      <span class="code-text">核销码</span>
      <span class="use-situation">（{{orderInfo.order_status*1===10?'未使用':'已使用'}}）</span>
    </div>
    <div class="code-box-layout">
      <div class="code-img-box">
        <img :src="orderInfo.check_code_img" class="icon-img" />
      </div>
      <div class="use-img-box" v-if="orderInfo.order_status*1!==10">
        <img src="~@/assets/order/an_icon_has_been_used@2x.png" class="icon-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "code-card",
  props:['orderInfo'],
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.card {
  .icon-img {
    display: block;
    width: 100%;
  }
  padding: 12px;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 16px;
  .title-box {
    display: flex;
    align-items: center;
    .titel-icon {
      width: 18px;
    }
    .code-text {
      font-weight: 600;
      padding-left: 10px;
    }
    .use-situation {
      color: #aaaaaa;
      padding-left: 10px;
    }
  }
  .code-box-layout {
    position: relative;
    padding: 48px 118px 32px 118px;
    .code-img-box {
      width: 130px;
    }
    .use-img-box {
      position: absolute;
      top: 22px;
      right: 100px;
      width: 80px;
    }
  }
}
</style>
