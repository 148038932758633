import request from './ajax'
// 公益豆模块路由
const benefitUrl = '/api/benefit/'
// 公益豆商品列表
export const getBenefitList = (data) => request(benefitUrl + 'goods', 'post', { ...data })
// 获取公益豆商品详情
export const getBenefitInfo = (data) => request(benefitUrl + 'goods_info', 'post', { ...data })
// 获取公益豆商品规格
export const getBenefitSpec = (data) => request(benefitUrl + 'spec', 'post', { ...data })
// 创建公益豆订单
export const createBenefitOrder = (data) => request(benefitUrl + 'create', 'post', { ...data })
// 用户核销订单
export const checkOrder = (data) => request(benefitUrl + 'check_order', 'post', { ...data })
// 获取默认店铺信息
export const getDefaultShopInfo = (data) => request(benefitUrl + 'default_shop', 'post', { ...data })
// 获取我的公益豆信息
export const getMyBenefitList = (data) => request(benefitUrl + 'my_benefit', 'post', { ...data })
