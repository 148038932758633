<template>
  <div v-if="orderInfo.id * 1 > 0">
    <NavBar class="nav-bar">
      <div slot="title">订单详情</div>
    </NavBar>
    <div class="back-color"></div>
    <!-- 订单内容 -->
    <div class="content-layout">
      <div class="content-cards">
        <div class="await-pay">
          <div class="img-box">
            <img
              src="~@/assets/common/payment_pending_icon@2x.png"
              class="img"
            />
          </div>
          <span>{{ orderInfo.order_status_str }}</span>
        </div>
        <!-- 地址卡片 -->
        <div class="user-info-card" v-if="orderInfo.order_type * 1 != 3">
          <van-icon
            name="location-o"
            class="position-img-box"
            color="#9B9B9B"
            size="20"
          />
          <div class="user-content">
            <div class="user-info">
              <span class="name">{{ orderInfo.consignee }}</span>
              <span class="user-mobile">{{ orderInfo.consignee_tel }}</span>
            </div>
            <div class="address-text">
              <span>{{ orderInfo.city_names }} {{ orderInfo.address }} </span>
            </div>
          </div>
        </div>

        <!-- 取货地址 -->
        <!-- 根据需求判断是否需要显示，实体店进货模块 -->
        <div class="shop-address-card" v-if="orderInfo.order_type * 1 === 3">
          <div class="shop-img-box">
            <img
              src="~@/assets/common/store_positioning_icon@2x.png"
              class="shop-icon"
            />
          </div>
          <div class="address-text">
            <div>
              <span class="shop-name">{{
                orderInfo.claim_shop.shop_name
              }}</span>
              <span class="shop-phone">{{
                orderInfo.claim_shop.shop_phone
              }}</span>
            </div>
            <div class="address-detail">
              {{ orderInfo.claim_shop.city_name
              }}{{ orderInfo.claim_shop.address }}
            </div>
          </div>
          <van-icon name="arrow" />
        </div>
        <CodeCard
          :orderInfo="orderInfo"
          v-if="
            orderInfo.order_type * 1 == 3 && orderInfo.check_code_img !== ''
          "
        ></CodeCard>
        <!-- 商品内容 -->
        <div class="goods-content">
          <div class="content-card">
            <!-- 店铺名称 -->
            <div class="shop-title-left">
              <van-icon name="shop-o" />
              <span class="title-text">供应商：{{ orderInfo.shop_name }}</span>
              <van-icon name="arrow" />
            </div>
            <!-- 商品信息 -->
            <div
              class="goods-info"
              v-for="(goods, g_index) in orderInfo.goods_list"
              :key="g_index"
            >
              <div class="goods-images-box">
                <div class="buy-details">
                  <div class="goods-img-box">
                    <img :src="goods.goods_img" class="goods-img" />
                  </div>
                  <div class="sku-info">
                    <div>{{ goods.goods_name }}</div>
                    <span class="specifications">{{ goods.item_key }}</span>
                  </div>
                </div>
              </div>
              <div class="price-quantity">
                <div class="price">
                  <span>
                    <span>￥</span>
                    <span>{{ toInteger(goods.price) }}</span>
                    <span>{{ twoFloating(goods.price) }}</span>
                  </span>
                </div>
                <div class="quantity">x{{ goods.goods_num }}</div>
              </div>
            </div>
            <!-- 总价 -->
            <div class="total-price">
              <div class="layout">
                <span>商品总价</span>
                <span>￥{{ twoNum(orderInfo.goods_money||0)}}</span>
              </div>
              <div class="layout">
                <span>配送费</span>
                <span class="courier-price">￥{{orderInfo.postage}}</span>
              </div>
              <div class="layout">
                <span>消费金</span>
                <span class="discounts-price">￥{{ orderInfo.consume_money||0}}</span>
              </div>
              <div class="layout">
                <span>合计</span>
                <span class="sum-price"
                  >￥{{ twoNum(orderInfo.total_money) }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 订单客服 -->
        <div class="order-info-card">
          <!-- 这里的下划线根据情况判断是否需要展示 -->
          <div class="order-code layout">
            <span>订单编号：</span>
            <span class="color">{{ orderInfo.order_sn }}</span>
            <div
              class="copy"
              v-clipboard:copy="orderInfo.order_sn"
              v-clipboard:success="copyOk"
            >
              复制
            </div>
          </div>
          <div class="order-time layout">
            <span>创建时间：</span>
            <span class="color">{{ orderInfo.created_at }}</span>
          </div>
          <!-- 是否需要展示支付方式和支付时间 -->
          <div
            v-if="
              orderInfo.order_status * 1 > 1 && orderInfo.order_status * 1 < 6
            "
          >
            <div class="layout">
              <span>支付方式：</span>
              <span class="color">{{ orderInfo.pay_name }}</span>
            </div>
            <div class="order-time layout">
              <span>支付时间：</span>
              <span class="color">{{ orderInfo.pay_time }}</span>
            </div>
          </div>
          <!-- 是否需要展示配送方式-->
          <div
            class="order-time layout"
            v-if="
              orderInfo.order_status * 1 > 2 &&
              orderInfo.order_status * 1 < 6 &&
              orderInfo.order_type * 1 === 3
            "
          >
            <span>配送方式：</span>
            <span class="color">普通快递</span>
          </div>
          <!-- 是否需要展示配送日期-->
          <div
            class="order-time layout"
            v-if="
              orderInfo.order_status * 1 > 2 &&
              orderInfo.order_status * 1 < 6 &&
              orderInfo.order_type * 1 === 3
            "
          >
            <span>配送日期：</span>
            <span class="color">{{ orderInfo.shipping_time }}</span>
          </div>
          <div class="service" @click="contactService">
            <div class="service-img-box">
              <img
                src="~@/assets/common/kefu_icon@2x.png"
                class="service-img"
              />
            </div>
            <span class="contact">联系客服</span>
          </div>
        </div>
      </div>
      <div>
        <GoodsShowLabel :tagId="0" :sendType="3">
          <div slot="detailsTitle" class="details-title">-为你推荐-</div>
        </GoodsShowLabel>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <div class="bottom-button">
      <div
        class="button-box"
        v-if="orderInfo.order_status <= 2 && orderInfo.order_type * 1 !== 3"
        @click.stop
        @click="updateAddress()"
      >
        <van-button plain round block>修改地址</van-button>
      </div>
      <div
        class="button-box"
        v-if="orderInfo.order_status === 1"
        @click.stop
        @click="switchFn(1)"
      >
        <van-button plain round block>取消订单</van-button>
      </div>
      <div
        class="button-box"
        v-if="orderInfo.order_status === 1"
        @click.stop
        @click="setOrderPay()"
      >
        <van-button type="danger" round block>立即付款</van-button>
      </div>
      <div
        class="button-box"
        v-if="orderInfo.order_status === 3"
        @click.stop
        @click="goToUrl()"
      >
        <van-button type="danger" round block>查看物流</van-button>
      </div>
      <div
        class="button-box"
        v-if="orderInfo.order_status === 3"
        @click.stop
        @click="switchFn(2)"
      >
        <van-button type="danger" round block>确认收货</van-button>
      </div>
      <div
        class="button-box"
        v-if="orderInfo.order_status === 10"
        @click.stop
        @click="switchFn(3)"
      >
        <van-button type="danger" round block>确认自提</van-button>
      </div>
    </div>
    <!-- 提示弹出层 -->
    <PromptPopup
      :showPopup="showPopup"
      :msg="showMsg"
      @popupFn="popupFn"
    ></PromptPopup>
    <!--    地址管理-->
    <AddressList
      v-if="showAddress"
      @setAddressInfo="setAddressInfo"
      :orderType="1"
    ></AddressList>
    <!--    订单支付-->
    <OrderPay
      v-if="showPay"
      :showPay="showPay"
      :orderId="orderId"
      @closeOverlay="endOrderPay"
    ></OrderPay>
  </div>
</template>

<script>
import PromptPopup from "@/components/prompt-popup/prompt-popup";
import NavBar from "@/layout/nav-bar/nav-bar";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import CodeCard from "../public-benefit/code-card";
import {
  cancelOrder,
  getOrderInfo,
  setDelivery,
  updateAddress,
} from "@/api/order";
import { Toast } from "vant";
import { checkOrder } from "@/api/benefit";
import OrderPay from "@/components/pay-shade/pay-shade";
import AddressList from "@/pages/my/address-list/address-list";
import tools from "@/common/js/tools";
export default {
  name: "my-order-details",
  components: {
    NavBar,
    GoodsShowLabel,
    CodeCard,
    PromptPopup,
    OrderPay,
    AddressList,
  },
  data() {
    return {
      showAddress: false,
      showPopup: false,
      showPay: false,
      showMsg: "",
      shopId: this.$route.query.shopId || 0,
      orderId:
        this.$route.query.orderId === undefined ? 0 : this.$route.query.orderId,
      orderInfo: { id: 0 },
    };
  },
  mounted() {
    console.log(this.shopId);
    this.getOrderInfo();
  },
  methods: {
    copyOk() {
      Toast.success("订单编号复制成功");
    },
    contactService() {
      this.$router.push({
        name: "information",
        query: { shopId: this.shopId, groupType: 2 },
      });
    },
    endOrderPay(bool) {
      if (bool) {
        //支付成功
      }
      this.showPay = false;
    },
    setOrderPay() {
      this.showPay = true;
    },
    updateAddress() {
      //显示修改地址
      this.showAddress = true;
    },
    async setAddressInfo(Ainfo) {
      //订单修改收货地址
      if (this.orderId * 1 < 0) {
        Toast.fail("订单信息错误");
        return false;
      }
      const ret = await updateAddress({
        order_id: this.orderId,
        address_id: Ainfo.id,
      });
      if (ret.code * 1 == 1) {
        this.orderInfo.city_names = Ainfo.city_names;
        this.orderInfo.consignee_tel = Ainfo.phone;
        this.orderInfo.consignee = Ainfo.consignee;
        this.orderInfo.address = Ainfo.address;
        Toast.success("地址信息修改成功");
      }
      this.showAddress = false;
    },
    switchFn(showType) {
      //显示提示弹框
      this.showType = showType;
      switch (showType) {
        case 1:
          this.showMsg = "是否取消此订单";
          break;
        case 2:
          this.showMsg = "确认收到货了吗？";
          break;
        case 3:
          this.showMsg = "确认已经拿到货了吗？";
          break;
        default:
      }
      this.showPopup = true;
    },
    popupFn(bool) {
      // 弹框回调通知
      this.showPopup = false;
      if (bool) {
        switch (this.showType) {
          case 1:
            //执行取消订单操作
            this.cancelOrder();
            break;
          case 2:
            //执行取消订单操作
            this.setDelivery();
            break;
          case 3:
            //执行取消订单操作
            this.checkOrder();
            break;
          default:
        }
      }
    },
    async setDelivery() {
      // 确认收货
      const ret = await setDelivery({ order_id: this.orderId });
      if (ret.code * 1 === 1) {
        Toast.success("确认收货成功");
        //修改订单状态
        this.orderInfo.order_status = 5;
        this.orderInfo.order_status_str = "已完成";
      }
    },
    async cancelOrder() {
      // 订单取消
      const ret = await cancelOrder({ order_id: this.orderId });
      if (ret.code * 1 === 1) {
        Toast.success("订单取消成功");
        this.orderInfo.order_status = 6;
        this.orderInfo.order_status_str = "交易关闭";
      }
    },
    async checkOrder() {
      const ret = await checkOrder({ order_id: this.orderId });
      if (ret.code * 1 === 1) {
        Toast.success("自提成功");
        this.orderInfo.order_status = 6;
        this.orderInfo.order_status_str = "交易完成";
      }
    },
    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf("."));
      return result;
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getOrderInfo() {
      const ret = await getOrderInfo({ order_id: this.orderId });
      if (ret.code * 1 == 1) {
        this.orderInfo = ret.data;
      }
    },
    goToUrl() {
      //页面跳转
      this.$router.push({
        name: "examine-logistics",
        query: { orderId: this.orderId },
      });
    },

    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数，自动补零，保留两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.details-title {
  font-size: 16px;
  font-weight: normal;
}
.content-cards {
  padding: 0 12px;
}
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.back-color {
  padding-top: 52px;
  height: 116px;
  background: linear-gradient(#f4f7ff, #f9f9f9);
}

.content-layout {
  position: absolute;
  top: 82px;
  right: 0;
  left: 0;
}
// 待付款
.await-pay {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 20px;
  font-size: 23px;
  font-weight: 500;

  .img-box {
    width: 30px;
    margin-right: 10px;

    .img {
      display: block;
      width: 100%;
    }
  }
}

// 地址选择卡片
.user-info-card {
  display: flex;
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  font-size: 17px;
  .position-img-box {
    display: flex;
    align-items: center;
  }
  .user-content {
    padding-left: 10px;

    .name {
      font-size: 18px;
    }

    .user-mobile {
      color: #b9babe;
      padding-left: 10px;
    }

    .address-text {
      color: #6f6f6f;
    }
  }
}
// 取货地址
.shop-address-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
  margin-top: 12px;
  .shop-img-box {
    flex-shrink: 0;
    width: 20px;
    .shop-icon {
      width: 100%;
      display: block;
    }
  }
  .address-text {
    margin-top: 6px;
    padding: 0 10px;
    font-size: 16px;
  }
  .shop-name {
    font-weight: 500;
  }
  .shop-phone {
    color: #b9babe;
    margin-left: 10px;
  }
  .address-detail {
    color: #6f6f6f;
  }
}

// 订单卡片样式
.goods-content {
  margin-top: 12px;
}

.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;

  .shop-title-left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #c6c6c6;

    .title-text {
      margin: 0 4px;
      font-size: 17px;
      font-weight: 500;
      color: #000;
    }
  }
}

// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  .goods-images-box {
    overflow-x: scroll;
  }
  .buy-details {
    display: flex;
    justify-content: center;
    .goods-img-box {
      flex-shrink: 0;
      width: 90px;
      .goods-img {
        display: block;
        width: 100%;
        height: 90px;
        border-radius: 6px;
      }
    }

    .sku-info {
      margin-left: 12px;
      font-size: 16px;
      div {
        margin-bottom: 20px;
      }
      .specifications {
        background-color: #f5f5f5;
        padding: 4px 19px;
        border-radius: 6px;
        color: #6f6f6f;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .price {
      font-size: 16px;
    }
    .quantity {
      margin-top: 16px;
      color: #aaaaaa;
    }
  }
}

.total-price {
  .layout {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 6px 0;

    .courier-price {
      color: #6f6f6f;
    }
    .discounts-price {
      color: #fd6d1f;
    }
    .sum-price {
      color: #e61f18;
    }
  }
}

// 订单客服
.order-info-card {
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;
  border-radius: 16px;
  .color {
    color: #6f6f6f;
    padding-left: 20px;
    font-weight: normal;
  }

  .layout {
    padding-bottom: 10px;
    font-size: 16px;
  }

  .order-code {
    display: flex;
    align-items: center;
    font-size: 16px;
    .copy {
      width: 39px;
      height: 19px;
      border: 1px solid #e51f18;
      opacity: 1;
      border-radius: 6px;
      line-height: 19px;
      font-size: 12px;
      color: #e51f18;
      text-align: center;
      margin-left: 11px;
    }
  }

  .order-time {
    font-size: 16px;
    border-bottom: 1px solid #ededed;
  }

  .service {
    padding: 12px 12px 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .service-img-box {
      width: 20px;

      .service-img {
        display: block;
        width: 100%;
      }
    }

    .contact {
      color: #aaaaaa;
      margin-left: 10px;
    }
  }
}

.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  padding: 10px 12px;

  .button-box {
    padding: 0 10px;

    .van-button--normal {
      padding: 20px;
    }
  }
}
</style>
