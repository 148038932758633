import request from './ajax'
// 商品模块路由
const goodsUrl = '/api/goods/'
// 获取首页商品标签
export const indexTag = (data) => request(goodsUrl + 'index_tag', 'post', { ...data })
// 获取标签商品列表
export const tagGoods = (data) => request(goodsUrl + 'tag_goods', 'post', { ...data })
// 商品列表
export const goodsList = (data) => request(goodsUrl + 'list', 'post', { ...data, openLoding: 1 })
// 猜你喜欢
export const LikeGoods = (data) => request(goodsUrl + 'like_goods', 'post', { ...data })
// 商品分类
export const GoodsClass = (data) => request(goodsUrl + 'class', 'post', { ...data })
// 获取商品详情
export const getGoodsInfo = (data) => request(goodsUrl + 'goods', 'post', { ...data })
// 获取商品评价
export const getCommentList = (data) => request(goodsUrl + 'get_comment', 'post', { ...data })
// 获取商品评价标签
export const getGoodsTag = (data) => request(goodsUrl + 'tag', 'post', { ...data })
// 获取商品规格
export const getGoodsSpec = (data) => request(goodsUrl + 'spec', 'post', { ...data })
// 获取当前商品是否收藏
export const getIsCollect = (data) => request(goodsUrl + 'is_collect', 'post', { ...data })
// 收藏商品
export const setGoodsCollect = (data) => request(goodsUrl + 'collect', 'post', { ...data })
// 商品收藏列表
export const getCollectList = (data) => request(goodsUrl + 'collect_list', 'post', { ...data })
// 获取收索记录
export const getSearch = (data) => request(goodsUrl + 'search', 'post', { ...data })
// 删除收索记录
export const delSearch = (data) => request(goodsUrl + 'del_search', 'post', { ...data })
// 我的评价
export const getMyRemainList = (data) => request(goodsUrl + 'my_comment', 'post', { ...data })
// 获取待评价列表
export const getRemainList = (data) => request(goodsUrl + 'remain', 'post', { ...data })
// 获取待评价商品详情
export const getRemainInfo = (data) => request(goodsUrl + 'remain_info', 'post', { ...data })
// 商品评价
export const setComment = (data) => request(goodsUrl + 'set_comment', 'post', { ...data })
//商品分享信息
export const getGoodsShareInfo = (data) => request(goodsUrl + 'share', 'post', { ...data })

export const getDiscountsInfo = (data) => request(goodsUrl + 'discounts', 'post', data)
