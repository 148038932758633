<template>
  <div class="max-box">
    <div class="title">
      <slot name="detailsTitle"></slot>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      offset="300"
      finished-text="没有更多了"
      @load="onLoad"
      v-if="!isBlank"
    >
      <van-grid column-num="2" :border="false">
        <template default>
          <div
            class="goods-detail"
            v-for="(goods, index) in goods_list"
            :key="index"
            @click="goodsInfo(index)"
            @click.stop
          >
            <div class="goods-img-box">
              <img :src="goods.cover_img" class="goods-img" />
            </div>
            <div class="positio-img" v-if="false">
              <img
                src="~@/assets/common/consumption-golabel.png"
                class="icon-img"
              />
            </div>
            <div class="details-info">
              <div class="goods-title">{{ goods.good_name }}</div>
              <div class="score-info">
                <div
                  v-if="goods.p_type === undefined || goods.p_type * 1 <= 1"
                  class="score-info"
                >
                  <div class="stars-img-box">
                    <img
                      src="~@/assets/common/icon-good-opinion.png"
                      class="stars-img"
                    />
                  </div>
                  <span>{{ goods.score }}分</span>
                  <span>|</span>
                  <span>{{ goods.sales }}人付款</span>
                </div>
                <div v-else-if="goods.p_type * 1 === 2" class="tianmao-tag">
                  天猫好物
                </div>
                <div v-else class="jingdong-tag">京东优选</div>
              </div>
              <div class="price">
                <div class="present-price">
                  <span>￥</span>
                  <span class="textSize">{{ toInteger(goods.price) }}</span>
                  <span>{{ twoFloating(goods.price) }}</span>
                </div>
                <div class="original-price">
                  <span>￥</span>
                  <span>{{ toInteger(goods.original_price) }}</span>
                  <span>{{ twoFloating(goods.original_price) }}</span>
                  <!-- <span>￥</span>
                  <span>{{ integerNum(goods.original_price) }}</span>
                  <span>{{ floorNum(goods.original_price) }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-grid>
    </van-list>
    <div class="blank-page" v-else>
      <img
        src="~@/assets/blank/collect-blank-images@2x.png"
        class="blank-img"
      />
    </div>
  </div>
</template>

<script>
import { tagGoods, goodsList, LikeGoods } from "@/api/goods.js";
import tools from "@/common/js/tools";
export default {
  name: "goods-show-label",
  props: {
    goodsType: {
      default: 0,
    },
    tagId: {
      // 根据id获取不同的列表
      type: Number,
      default: 0,
    },
    classId: {
      type: Number,
      default: 0,
    },
    goodName: {
      type: String,
      default: "",
    },
    orderBy: {
      type: Number,
      default: 0,
    },
    sendType: {
      type: Number,
      default: 0,
    },
    shopId: {
      default: "0",
    },
    isExpense: {
      type: Number,
      default: 0,
    },
    isDiscount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      buttonShow: 2, // 根据不同的状态码判断显示那个按钮
      goods_list: [],
      page: 1,
      total: 100,
      loading: false,
      finished: false,
      isAjax: false,
      goodsName: "",
      isBlank: false,
      platformType: 0,
    };
  },
  mounted() {
    this.goodsName = this.goodName;
    if (this.goodsType !== 0) {
      this.platformType = this.goodsType;
    } else {
      this.platformType = tools.platformType();
    }
  },
  methods: {
    onLoad() {
      this.getGoodsList();
    },
    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf("."));
      return result;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = num * 1;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    reloadGoods() {
      this.isBlank = false;
      this.isAjax = false;
      this.total = 100;
      this.page = 1;
      this.goods_list = [];
      this.getGoodsList();
    },
    async getGoodsList() {
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      if (this.total <= this.page) {
        return false;
      }
      // 请求分流头
      switch (this.sendType * 1) {
        case 1:
          this.tagGoods();
          break;
        case 2:
          this.goodsList();
          break;
        default:
          this.LikeGoods();
      }
    },
    async tagGoods() {
      const sendData = {
        tag_id: this.tagId,
        page: this.page,
      };
      const res = await tagGoods(sendData);
      this.setGoodList(res);
    },
    async goodsList() {
      const sendData = {
        good_name: this.goodsName,
        class_id: this.classId,
        shop_id: this.shopId,
        is_expense: this.isExpense,
        is_discount: this.isDiscount,
        order_by: this.orderBy,
        page: this.page,
        openLoding: 1,
        platform_type: this.platformType,
      };
      // console.log(
      //   "=======classID========",
      //   this.classId,
      //   "====shopId======",
      //   this.shopId
      // );
      const res = await goodsList(sendData);
      // console.log("商品列表返回数据==================", res);
      this.setGoodList(res);
    },
    async LikeGoods() {
      // 获取猜你喜欢列表
      const res = await LikeGoods({ platform_type: this.platformType });
      this.setGoodList(res);
    },
    setGoodList(goodsList) {
      // 商品列表返回处理
      // console.log(goodsList);
      // if (goodsList.data.list.length <= 0) {
      //   this.$emit("showBlank", true);
      // } else {
      //   this.$emit("showBlank", false);
      // }
      if (goodsList.code * 1 === 1) {
        if (goodsList.data.total <= 0) {
          this.isBlank = true;
        }
        ++this.page;
        this.goods_list = this.goods_list.concat(goodsList.data.list);
        //添加重复数据
        let isRepetition = true;
        while (isRepetition) {
          if (this.goods_list.length < 10 && this.goods_list.length > 0) {
            this.goods_list = this.goods_list.concat(goodsList.data.list);
          } else {
            isRepetition = false;
          }
        }

        this.total = goodsList.data.total;
        this.loading = false;
        if (this.goods_list.length >= this.total) {
          // this.finished = true;
          this.page = 1;
        }
      }
      this.isAjax = false;
    },
    goodsInfo(goodsIndex) {
      let goodsInfo = this.goods_list[goodsIndex];
      if (goodsInfo.p_type === undefined || goodsInfo.p_type * 1 === 1) {
        let goodsId = goodsInfo.id;
        localStorage.setItem("GOODS_ID", goodsId);
        this.$router.push({
          name: "goods",
          query: {
            goodsId: goodsId,
            type: 1,
          },
        });
      } else {
        window.location.href = goodsInfo.goods_link;
      }
    },
  },
  watch: {
    "_props.orderBy": function () {
      this.orderBy = this._props.orderBy;
      this.reloadGoods();
    },
    "_props.goodName": function () {
      this.goodsName = this._props.goodName;
      this.reloadGoods();
    },
    classId(n, o) {
      if (n !== o && this.shopId > 0) {
        this.reloadGoods();
      }
      // console.log("=======classID========", n, "====shopId======", this.shopId);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  line-height: 40px;
  font-weight: 600;
}
.max-box {
  padding: 10px 0;
}
.van-list {
  padding: 0 12px;
}
.van-grid {
  // .goods-detail {
  //   &:nth-child(2n + 1) {
  //     margin: 0 14px 12px 0;
  //   }
  // }
  // .goods-detail {
  //   &:nth-child(2n) {
  //     margin-bottom: 12px;
  //   }
  // }
  display: flex;
  gap: 14px;
}
.goods-detail {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  width: 188px;
  .goods-img-box {
    width: 188px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    .goods-img {
      display: block;
      width: 100%;
      height: 188px;
    }
  }
  .positio-img {
    position: absolute;
    top: 0;
    left: -3px;
    width: 54px;
    .icon-img {
      display: block;
      width: 100%;
    }
  }
}
.details-info {
  padding: 8px 12px;
}
.details-info {
  .score-info {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 6px 0;
    span {
      margin-left: 6px;
      color: #bbbbbb;
    }
    .stars-img-box {
      flex-shrink: 0;
      width: 12px;
      .stars-img {
        display: block;
        border-radius: 16px 16px 0 0;
        width: 100%;
      }
    }
    .jingdong-tag {
      display: table;
      padding: 1px 3px;
      color: #fff;
      background: linear-gradient(90deg, #f4180f 0%, #ff6731 100%);
      border-radius: 3px;
    }
    .tianmao-tag {
      display: table;
      padding: 1px 3px;
      border: 1px solid #985dff;
      color: #975bfe;
      border-radius: 3px;
    }
  }
}
.price {
  display: flex;
  align-items: center;
  .present-price {
    color: #ed301d;
    font-size: 14px;
  }
  .original-price {
    padding-left: 6px;
    font-size: 12px;
    color: #b9babe;
    text-decoration: line-through;
  }
}
.textSize {
  font-weight: 500;
  font-size: 18px;
}
.goods-title {
  font-size: 16px;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.blank-page {
  display: flex;
  justify-content: center;
  width: 100%;
  .blank-img {
    display: block;
    margin-top: 40px;
    width: 200px;
    height: 100px;
  }
}
</style>
