<template>
  <van-overlay :show="showPay" z-index="2001" duration="0">
    <div class="pay-details-title">
      <van-icon name="cross" class="close-btn" @click="closeBtn" />
      <span>支付详情</span>
    </div>
    <div class="pay-card">
      <div class="pay-info">
        <div class="remaining">支付剩余时间4小时</div>
        <div class="price">
          <div v-if="orderType === 3">
            {{ toInteger(payInfo.total_money) || 0 }}公益豆
          </div>
          <div v-else>
            <span class="price-title">￥</span>
            <span>{{ payInfo.total_money }}</span>
          </div>
        </div>

        <div class="shop-id">订单编号:{{ payInfo.order_sn }}</div>
      </div>
      <div v-if="payInfo.order_type * 1 != 3">
        <div class="pay-operation">
          <div class="money-now">
            <span class="title">当前余额</span>
            <span class="money">￥{{ twoNum(moneyInfo.money) }}</span>
          </div>
          <div class="input-money">
            <span class="title">余额支付</span>
            <div class="input-box">
              <span>￥</span>
              <div class="input">
                <input type="number" v-model="payMoney" />
              </div>
            </div>
          </div>
        </div>
        <van-radio-group v-model="radio">
          <van-cell-group :border="false" class="cell-group">
            <van-cell clickable @click="radio = 1" v-if="isWx">
              <template #title>
                <div class="pay-mode">
                  <img
                    class="wechat-icon"
                    width="20"
                    height="20"
                    src="~@/assets/order/wechat_icon@2x.png"
                  />
                  <span class="custom-title">微信支付</span>
                </div>
              </template>
              <template #right-icon>
                <van-radio :name="1" checked-color="#ED301D" />
              </template>
            </van-cell>
            <van-cell clickable @click="radio = 2" v-else>
              <template #title>
                <div class="pay-mode">
                  <img
                    class="wechat-icon"
                    width="20"
                    height="20"
                    src="~@/assets/order/zhifubao.png"
                  />
                  <span class="custom-title">支付宝</span>
                </div>
              </template>
              <template #right-icon>
                <van-radio :name="2" checked-color="#ED301D" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div v-else class="pay-info">
        <van-cell
          title="当前公益豆"
          is-link
          :value="twoNum(moneyInfo.bean_money)"
          :border="false"
        />
        <div class="lines-pay">
          <span>公益豆支付</span>
          <div class="lines-pay-son">
            <div class="fillIn-the-input-box">
              <input
                type="number"
                class="fillIn-the-input"
                disabled
                v-model="payMoney"
              />
            </div>
            <!-- <span>公益豆</span> -->
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <van-button
        color="linear-gradient(to right, #FA6A5A, #FB604E,#F83820)"
        block
        round
        @click="startPay"
      >
        确认支付
      </van-button>
    </div>
    <!--支付宝支付内容-->
    <div id="zfb-pay" v-html="zfbPay"></div>
    <!-- 密码弹出层 -->
    <van-popup
      v-model="passwordPopup"
      :closeable="true"
      round
      close-icon-position="top-left"
      :close-on-click-overlay="false"
      @click-close-icon="passwordPopup = false"
    >
      <div class="password-layout">
        <div class="enter-password">
          <div class="password-title">请输入支付密码</div>
          <div class="pay-price" v-if="orderType !== 3">
            ￥{{ twoNum(payMoney) }}
          </div>
          <div class="pay-price-beans" v-else>
            {{ parseInt(payMoney) }}公益豆
          </div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="passStr"
          :focused="isFocus"
          @focus="show = true"
        />
      </div>
    </van-popup>
    <van-number-keyboard
      z-index="999999"
      :show="show"
      theme="custom"
      close-button-text="完成"
      @blur="show = false"
      @input="setPass"
      @delete="delPass"
    />
  </van-overlay>
</template>

<script>

import { Dialog } from "vant";
import { getOrderPayInfo, setOrderPay } from "@/api/order";
import { getMoneyInfo } from "@/api/member";
import { getDefaultShopInfo } from "@/api/benefit";
import { Toast } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "order-pay",
  props: ["showPay", "orderId", "orderType"],
  data() {
    return {
      radio: 1,
      passwordPopup: false, // 密码弹出层
      passStr: "",
      value: 0,
      show: false,
      zfbPay: "",
      payMoney: "",
      payType: "",
      isFocus: true,
      payInfo: {
        order_sn: "",
        total_money: "0",
        order_type: "1",
      },
      moneyInfo: { money: 0, consume_money: 0, bean_money: 0 },
      isWx: true,
    };
  },
  watch: {
    payMoney: function () {
      if (this.payMoney * 1 < 0) {
        this.payMoney = "";
      }
      if (this.payMoney * 1 > this.payInfo.total_money) {
        this.payMoney = this.payInfo.total_money;
      }
      if (this.payInfo.order_type * 1 !== 3) {
        if (this.moneyInfo.money * 1 < this.payMoney) {
          this.payMoney = this.moneyInfo.money * 1;
        }
      }
    },
  },
  mounted() {
    this.getOrderPayInfo();
    this.isWx = tools.isWx();
    if (this.isWx) {
      tools.wxInitialize(["chooseWXPay"]);
      this.radio = 1;
    } else {
      this.radio = 2;
    }
  },
  methods: {
    sendPay() {
      if (this.payInfo.order_type * 1 !== 3 && this.payMoney * 1 <= 0) {
        this.setOrderPay();
      } else {
        // this.passwordPopup = true;
        this.setOrderPay();
      }
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    startPay() {
      //关闭支付密码

      if (
        (this.payInfo.order_type * 1 === 3 || this.payMoney * 1 > 0) &&
        false
      ) {
        if (this.payInfo.set_pay_pass * 1 <= 0) {
          Dialog.confirm({
            title: "提示",
            message: "请设置支付密码",
            cancelButtonText: "继续支付",
            confirmButtonText: "设置密码",
          })
            .then(() => {
              this.$router.replace("/alter-password");
            })
            .catch(() => {
              if (this.isWx) {
                this.radio = 1;
              } else {
                this.radio = 2;
              }
              this.payMoney = "";
              this.setOrderPay();
            });
        } else {
          this.passwordPopup = true;
        }
      } else {
        this.setOrderPay();
      }
    },
    async setOrderPay() {
      //订单支付
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await setOrderPay({
        balance_money: this.payMoney,
        order_id: this.orderId,
        pay_pass: this.passStr,
        pay_type: this.radio,
      });
      if (ret.code * 1 === 1) {
        if (ret.data.pay_status) {
          //跳转到支付成功页面
          this.goToPaySuccess();
        } else {
          //调用对应模块的支付
          if (this.radio * 1 === 1) {
            let _that = this;
            wx.chooseWXPay({
              timestamp: ret.data.wx_pay.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: ret.data.wx_pay.nonceStr, // 支付签名随机串，不长于 32 位
              package: ret.data.wx_pay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: ret.data.wx_pay.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: ret.data.wx_pay.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                // _that.$router.push({
                //   name: "pay-success",
                //   query: {
                //     orderId: this.orderId,
                //     payMoney: this.payInfo.total_money,
                //     orderType: this.payInfo.order_type,
                //   },
                // });
                _that.goToPaySuccess();
              },
            });
          } else {
            this.zfbPay = ret.data.zfb_pay;
            setTimeout(function () {
              document.forms["alipaysubmit"].submit();
            }, 50);
          }
        }
      } else if (ret.code * 1 === 101) {
        this.isAjax = false;
        //设置密码弹框
        // 确认支付
        Dialog.confirm({
          title: "提示",
          message: ret.msg,
          cancelButtonText: "继续支付",
          confirmButtonText: "设置密码",
        })
          .then(() => {
            this.$router.replace("/alter-password");
          })
          .catch(() => {
            if (this.isWx) {
              this.radio = 1;
            } else {
              this.radio = 2;
            }
            this.payMoney = "";
            this.setOrderPay();
          });
      } else {
        Toast.fail(ret.msg);
      }
      this.isAjax = false;
    },
    goToPaySuccess() {
      this.$router.push({
        name: "pay-success",
        query: {
          orderId: this.orderId,
          payMoney: this.payInfo.total_money,
          orderType: this.payInfo.order_type,
        },
      });
    },
    async getMoneyInfo() {
      //获取会员资金信息
      const ret = await getMoneyInfo();
      // console.log("会员资金信息----------", ret);
      if (ret.code * 1 === 1) {
        this.moneyInfo = ret.data;
        console.log(this.moneyInfo);
        if (this.moneyInfo.money * 1 > 0) {
          this.payMoney = this.payInfo.total_money;
          // if(this.payInfo.total_money>this.moneyInfo.money){
          //
          // }else {
          //
          // }
        }
      }
    },
    async getOrderPayInfo() {
      //获取订单支付信息
      const ret = await getOrderPayInfo({ order_id: this.orderId });
      if (ret.code * 1 === 1) {
        this.payInfo = ret.data;
        if (this.payInfo.order_type * 1 === 3) {
          this.payMoney = this.payInfo.total_money;
        }
        this.isPay = true;
        if (this.payInfo.order_type * 1 != 3) {
          this.getMoneyInfo();
        } else {
          this.getConsumeMoneyInfo();
        }
      }
    },
    async getConsumeMoneyInfo() {
      const ret = await getDefaultShopInfo({ shop_id: this.payInfo.shop_id });
      if (ret.code * 1 === 1) {
        this.moneyInfo.bean_money = ret.data.bean_money;
      }
    },
    delPass() {
      //密码清除
      let passNum = this.passStr.length;
      if (passNum > 1) {
        this.passStr = this.passStr.substr(0, passNum - 1);
      } else {
        this.passStr = "";
      }
    },
    setPass(key) {
      //输入密码触发
      if (this.passStr.length < 6) {
        this.passStr += key;
      }
      if (this.passStr.length * 1 == 6) {
        //调用支付
        this.show = false;
        this.passwordPopup = false;
        this.setOrderPay();
        this.passStr = "";
      }
    },
    closeBtn() {
      this.$emit("closeOverlay");
    },
    onFocus() {
      if (this.isFocus) {
        this.showKeyboard = true;
        this.show = true;
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.cell-group {
  padding-left: 12px;
  padding-right: 12px;
}
.van-button__text {
  font-size: 18px;
}
// 遮罩层样式
.van-overlay {
  background-color: #f9f9f9 !important;
  display: block !important;
}
// 导航栏
.pay-details-title {
  position: relative;
  background-color: #fff;
  text-align: center;
  // padding: 12px;
  height: 57px;
  line-height: 57px;
  font-size: 21px;
  .close-btn {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.pay-card {
  margin: 12px;
  background-color: #fff;
  font-size: 18px;
  border-radius: 14px;
  .pay-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 12px 20px 12px;
    border-bottom: 1px solid #f2f2f2;
    .price {
      display: flex;
      align-items: center;
      font-size: 30px;
      font-weight: 500;
      margin: 8px 0;
      // .price-title {
      //   font-size: 26px;
      //   margin-right: 10px;
      // }
    }
    .shop-id {
      color: #6f6f6f;
    }
  }
  .pay-operation {
    padding: 12px 12px 0 12px;
    .money-now {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
    .input-money {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input-box {
        display: flex;
        align-items: center;
      }
      .input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 36px;
        border: 1px solid #707070;
        border-radius: 7px;

        input {
          width: 80%;
          height: 70%;
          border: none;
        }
      }
    }
  }
  .pay-mode {
    display: flex;
    align-items: center;
    img {
      display: block;
      margin-right: 10px;
    }
  }
}
.lines-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .lines-pay-son {
    display: flex;
    align-items: center;
  }
  .fillIn-the-input-box {
    border: 1px solid #707070;
    border-radius: 6px;
  }
  .fillIn-the-input {
    width: 85px;
    height: 36px;
    padding-left: 10px;
    border: none;
  }
}
.btn-box {
  padding: 12px;
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell-group {
  background-color: transparent;
}
// 密码弹出层
.password-layout {
  padding: 12px;
}
.enter-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;

  .password-title {
    color: #6f6f6f;
    font-size: 22px;
  }

  .pay-price {
    font-size: 30px;
    font-weight: 500;
    margin: 20px 0 40px 0;
  }
  .pay-price-beans {
    font-size: 26px;
    font-weight: 500;
    margin: 20px 0 40px 0;
  }
}
.van-cell__title {
  font-size: 18px;
}
.van-cell__value {
  font-size: 18px;
}
.van-cell {
  padding: 10px 0;
}
</style>
