import request from './ajax'
// 订单路由模块
const orderUrl = '/api/order/'
// 创建订单
export const createOrder = (data) => request(orderUrl + 'create', 'post', { ...data })
export const getUseCouponList = (data) => request(orderUrl + 'use-coupon', 'post', { ...data })
// 获取订单支付信息
export const getOrderPayInfo = (data) => request(orderUrl + 'pay_info', 'post', { ...data })
// 订单支付
export const setOrderPay = (data) => request(orderUrl + 'pay', 'post', { ...data })
// 订单列表
export const getOrderList = (data) => request(orderUrl + 'list', 'post', { ...data })
// 订单详情
export const getOrderInfo = (data) => request(orderUrl + 'item', 'post', { ...data })
// 确认收货
export const setDelivery = (data) => request(orderUrl + 'delivery', 'post', { ...data })
// 更新收货地址
export const updateAddress = (data) => request(orderUrl + 'address', 'post', { ...data })
// 取消订单
export const cancelOrder = (data) => request(orderUrl + 'cancel', 'post', { ...data })
// 订单退款退款
export const returnOrder = (data) => request(orderUrl + 'return', 'post', { ...data })
// 获取退货订单列表
export const returnList = (data) => request(orderUrl + 'return_list', 'post', { ...data })
// 获取退货订单详情
export const getReturnInfo = (data) => request(orderUrl + 'return_info', 'post', { ...data })
// 获取订单数量
export const getOrderNum = (data) => request(orderUrl + 'order_num', 'post', { ...data })
//获取订单列表标题
export const getOrderTitle = (data) => request(orderUrl + 'title', 'post', { ...data })
//获取物流信息
export const getExpressInfo = (data) => request(orderUrl + 'express', 'post', { ...data })
// 获取取消订单详情
export const getReturnReason = (data) => request(orderUrl + 'reason', 'post', { ...data })
// 获取退款退货商品
export const getReturnGoods = (data) => request(orderUrl + 'order_goods', 'post', { ...data })
// 删除订单
export const setDeleteOrder = (data) => request(orderUrl + 'del', 'post', { ...data })
// 删除退款/退货订单
export const setDeleteReturn = (data) => request(orderUrl + 'del-return', 'post', { ...data })
